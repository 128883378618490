.react-calendar.calendar-input {
  border: none;
  width: 100%;
  font-family: var(--font-wix-text), Arial, Helvetica, sans-serif;
  color: var(--black);
  background-color: transparent;
}

/* hide year navigation arrows */
.calendar-input .react-calendar__navigation__prev2-button,
.calendar-input .react-calendar__navigation__next2-button {
  display: none;
}

/* navigation header */
.calendar-input .react-calendar__navigation {
  height: auto;
  margin-bottom: var(--spacing-16);
}
.calendar-input .react-calendar__navigation__label__divider {
  display: none;
}
.calendar-input .react-calendar__navigation__label {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
}
.calendar-input .react-calendar__navigation__label:disabled,
.calendar-input .react-calendar__navigation__label:enabled:hover,
.calendar-input .react-calendar__navigation__label:enabled:focus {
  background-color: transparent;
}
.calendar-input .react-calendar__navigation__label__labelText {
  color: var(--black);
  font-size: 18px;
  font-weight: 800;
  text-transform: capitalize;
}

/* month navigation arrows */
.calendar-input button.react-calendar__navigation__arrow,
.calendar-input button.react-calendar__navigation__arrow:hover,
.calendar-input button.react-calendar__navigation__arrow:focus,
.calendar-input button.react-calendar__navigation__arrow:enabled:hover,
.calendar-input button.react-calendar__navigation__arrow:enabled:focus,
.calendar-input button.react-calendar__navigation__arrow:disabled {
  background-color: transparent;
  min-width: initial;
}
.calendar-input button.react-calendar__navigation__arrow {
  color: var(--black);
}
.calendar-input button.react-calendar__navigation__arrow:disabled {
  color: var(--gray);
}
.calendar-input .react-calendar__navigation__prev-button {
  padding-right: 20px;
}
.calendar-input .react-calendar__navigation__next-button {
  padding-left: 20px;
}

/* week days on top */
.calendar-input .react-calendar__month-view__weekdays__weekday {
  padding: 0 0 10px;
}
.calendar-input .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
  font-size: 12px;
  text-transform: lowercase;
  text-decoration: none;
  color: var(--black);
  white-space: nowrap;
}
.calendar-input .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr::after {
  content: '.';
}

/* calendar tiles (days) */
.calendar-input .react-calendar__tile {
  padding: 10px 0;
  background-color: transparent;
  transition: background-color .2s ease-in-out;
}
.calendar-input .react-calendar__tile abbr {
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
}
.calendar-input .react-calendar__tile:focus,
.calendar-input .react-calendar__tile:enabled:focus {
  background-color: transparent;
}
.calendar-input .react-calendar__tile:hover,
.calendar-input .react-calendar__tile:enabled:hover,
.calendar-input .react-calendar__tile--now:enabled:hover,
.calendar-input .react-calendar__tile--now:enabled:focus {
  background-color: var(--beige);
}
.calendar-input .react-calendar__tile:disabled:not(.react-calendar__tile--active):not(.react-calendar__tile--hasActive) {
  opacity: 0.8;
}
.calendar-input .react-calendar__tile:disabled:not(.react-calendar__tile--active):not(.react-calendar__tile--hasActive) abbr {
  color: var(--black<l);
}
.calendar-input .react-calendar__tile:disabled:hover {
  background-color: transparent;
}
.calendar-input .react-calendar__tile--now.react-calendar__tile--active:hover,
.calendar-input .react-calendar__tile--now.react-calendar__tile--active:focus,
.calendar-input .react-calendar__tile--now.react-calendar__tile--hasActive:hover,
.calendar-input .react-calendar__tile--now.react-calendar__tile--hasActive:focus,
.calendar-input .react-calendar__tile.react-calendar__tile--active,
.calendar-input .react-calendar__tile.react-calendar__tile--active:hover,
.calendar-input .react-calendar__tile.react-calendar__tile--active:focus,
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive,
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive:hover,
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive:focus {
  background-color: var(--sauge);
  color: var(--white);
}
.calendar-input .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd),
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd) {
  background-color: var(--yellow);
}
.calendar-input .react-calendar__tile.react-calendar__tile--active abbr,
.calendar-input .react-calendar__tile.react-calendar__tile--hasActive abbr{
  font-weight: 800;
  color: var(--white);
}
.calendar-input .react-calendar__tile.react-calendar__tile--range abbr{
  color: var(--black);
}
.calendar-input .react-calendar__tile--rangeStart abbr,
.calendar-input .react-calendar__tile--rangeEnd abbr{
  color: var(--white) !important;
}
.calendar-input .react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
  height: 0;
  padding: 0;
  pointer-events: none;
}

/* month picker view */
.calendar-input .react-calendar__year-view .react-calendar__tile,
.calendar-input .react-calendar__decade-view .react-calendar__tile {
  padding: 16px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
