/* Dropdown input field */

.select-field {
  position: relative;
  z-index: 1;
}

.select-field .select-field__control {
  min-height: 3.5rem;
  border: none;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
}

@media (width <= 768px) {
  .select-field .select-field__control {
    min-height: 3rem;
  }
}

.select-field .select-field__control:hover,
.select-field .select-field__control:focus {
  border: none;
  box-shadow: none;
}

.select-field .select-field__value-container {
  padding: 0 var(--spacing-4) 0 var(--spacing-16);
}

.select-field .select-field__placeholder {
  font: var(--font-p1-desktop-default);
  color: var(--gray);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (width <= 768px) {
  .select-field .select-field__placeholder {
    font: var(--font-p1-mobile-default);
  }
}

.select-field .select-field__input-container,
.select-field .select-field__single-value {
  font: var(--font-p1-desktop-default);
  color: var(--black);
  margin: 0;
  padding: 0;
}

@media (width <= 768px) {
  .select-field .select-field__input-container,
  .select-field .select-field__single-value {
    font: var(--font-p1-mobile-default);
  }
}

.select-field .select-field__indicators {
  padding-right: var(--spacing-8);
}

.select-field .select-field__indicators svg {
  color: var(--black);
  cursor: pointer;
}

.select-field .select-field__indicators .select-field__indicator-separator {
  display: none;
}

.select-field .select-field__indicators .select-field__dropdown-indicator {
  padding: 0 var(--spacing-8) 0 0;
}

/* Dropdown menu */

.select-field__menu-portal {
  opacity: 0;
  transition: opacity .2s ease-in-out;
  z-index: 2;
}

.select-field__menu-portal .select-field__menu {
  box-shadow: none;
  border: 1px solid var(--gray);
  border-radius: var(--radius-s);
  background-color: transparent;
  overflow: hidden;
}

.select-field__menu-portal .select-field__menu-list {
  padding: 0;
}

.select-field__menu-portal .select-field__menu-list .select-field__option,
.select-field__menu-portal .select-field__menu-list .select-field__option--is-focused,
.select-field__menu-portal .select-field__menu-list .select-field__option--is-selected,
.select-field__menu-portal .select-field__menu-list .select-field__option:hover,
.select-field__menu-portal .select-field__menu-list .select-field__option:focus {
  background-color: var(--white);
  color: var(--black);
}
.select-field__menu-portal .select-field__menu-list .select-field__option {
  padding: var(--spacing-12) var(--spacing-16);
  background-color: var(--white);
  font: var(--font-p2-desktop-default);
  cursor: pointer;
  transition: background-color .2s ease-in-out;
}

@media (width <= 768px) {
  .select-field__menu-portal .select-field__menu-list .select-field__option {
    font: var(--font-p2-mobile-default);
  }
}

.select-field__menu-portal .select-field__menu-list .select-field__option.select-field__option--is-selected:hover,
.select-field__menu-portal .select-field__menu-list .select-field__option.select-field__option--is-selected,
.select-field__menu-portal .select-field__menu-list .select-field__option:hover {
  background-color: var(--sauge);
  color: var(--white);
}