.pac-container {
  box-shadow: none;
  padding: var(--spacing-12);
  border: 1px solid var(--gray);
  border-radius: var(--radius-s);
  margin-top: var(--spacing-8);
}

.pac-container .pac-item {
  border: none;
  padding: 0;
  cursor: pointer;
}

.pac-container .pac-item span {
  font-size: 0.875rem;
}

.hdpi.pac-logo:after {
  display: none;
}
